<template>
  <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
    <p>Calculando resultados de presenças e notas dos alunos...</p>
  </div>
  <section v-if="!carregandoPagina">
    <div class="row">
      <div class="col-md-8">
        <p  style="font-size: 18px;margin-top: 25px;margin-bottom: 25px;">Ata número <b>{{ ata_numero }}</b> da <b>{{ escola_nome }}</b> e turma <b>{{turma_nome}}</b></p>
      </div>
      <div class="col-md-4" style="margin-top: 30px; text-align: right;">
        <pm-Button type="button" @click="this.$router.go(-1)" icon="pi pi-arrow-left"
          label="Voltar" title="Voltar" class="p-button-warning p-button-sm btn-color table_style" style="margin-left:5px"></pm-Button>
        <pm-Button type="button" @click="showModalRelatorio = true" icon="pi pi-eye"
          label="Ver Ata" title="Ver Ata" class="p-button-sm btn-color table_style" style="margin-left:5px" v-if="alunosALL.length"></pm-Button>
      </div>
    </div>

    <div v-if="alunosALL.length">
      <pm-DataTable :value="alunosALL"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
        :globalFilterFields="['nome']" responsiveLayout="scroll">


        <pm-Column field="numero" header="Aluno" style="font-size: 14px;vertical-align:middle !important">
          <template #body="{data}" >
            {{ data.info.nome }} {{ data.info.sobrenome }}          </template>
        </pm-Column>


        <pm-Column field="numero" header="Situação Atual" style="font-size: 14px;vertical-align:middle !important">
          <template #body="{data}" >
            <p v-if="data.situacao == 'PR' && ataInfo.segmento.tipo != 0" class="lbList" >{{ nomenclaturaData.aprovado }}</p>
            <p v-if="data.situacao == 'PR' && ataInfo.segmento.tipo == 0" class="lbList">{{ nomenclaturaData.aprovadoCreche }}</p>
            <p v-if="data.situacao == 'PRC'" class="lbList" style="width: 195px;">{{ nomenclaturaData.aprovadoPeloConselho }}</p>
            <p v-if="data.situacao == 'RJ'" class="lbList" style="background-color: #ac0c0c; width: 195px;">{{ nomenclaturaData.retencaoJustificada }}</p>

            <p v-if="data.situacao == 'RET'" class="lbList" style="background-color: #ac0c0c;">{{ nomenclaturaData.reprovado }}</p>

            <p v-if="data.situacao == 'TR'" class="lbList" style="background-color: #888585;">{{ nomenclaturaData.transferido }}</p>
            <p v-if="data.situacao == 'EV'" class="lbList" style="background-color: #888585;">{{ nomenclaturaData.evadido }}</p>
            <p v-if="data.situacao == 'MC'" class="lbList" style="background-color: #888585;">{{ nomenclaturaData.transferenciaExternaCreche }}</p>

            <p v-if="data.situacao == 'FAL'" class="lbList" style="background-color: #1a1919;">{{ nomenclaturaData.falecido }}</p>
          </template>
        </pm-Column>



        <pm-Column field="id"  headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
          <template #body="{data}" >
            <pm-Button type="button" icon="pi pi-pencil"
              :disabled="data.status != 'Normal' && data.status != 'Semestre 1 Encerrado'" label="Alterar" title="Alterar" class=" p-button-sm btn-color table_style" style="margin-left:5px; float: right;" @click="alterarDadosDoAluno(data)"></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>

    </div>
  </section>



  <pm-Dialog header="Visualizar Relatório"  v-model:visible="showModalRelatorio" :style="{ width: '90rem' }"
    :breakpoints="{ '960px': '80vw', '641px': '100vw' }">
    <div class="field col-12 md:col-12" style="padding-top: 16px" v-if="modo == 1">
      <div class="row">
        <div class="col-md-8">
          <pm-Button type="button" icon="pi pi-pencil"
            label="Alterar Cabeçalho" title="Alterar Cabeçalho" class="p-button-warning p-button-sm btn-color table_style" style="margin-left:5px;" @click="dadosAtas()"></pm-Button>

                &nbsp;&nbsp;

          <pm-Dropdown
            @change="mudarPadrao"
            v-model="exibicaoCabecalho"
            :options="modeloExibicao"
            optionLabel="nome"
          />
        </div>
        <div class="col-md-4">
          <button
            class="btn btn-danger button-generate"
            @click="gerarPdf()"
            title="Gerar Impressão"
            style="float: right;"
          >
            <i class="fa fa-file-pdf-o"></i> Pdf</button
          >
          <button class="btn btn-success mr-2" @click="gerarExcel()" style="float: right;"
            title="Gerar Excel">
            <i class="fa fa-file-excel-o"></i> Excel
          </button>

          <button class="btn btn-secondary mr-2" @click="gerarCsv('content-print-rel')" style="float: right;"
            title="Gerar csv">
            <i class="fa fa-file-o"></i> CSV
          </button>
        </div>
      </div>
    </div>
    <pm-Card>
      <template #content>
        <div  id="content-print-rel" style="font-size: 12px;" >
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
            <meta charset="utf-8">
          </head>
          <div class="row" v-if="modo == 1" style="padding-left: 50px;">
            <div >
              <div v-if="ataInfo.logo"  style=" text-align: center;" >
                <img :src="base_url+'/storage/'+ataInfo.logo"  :style="this.config.brasao == '100' ? 'width: auto;height: 100px;' : 'width:100%;' " />
              </div>
            </div>

            <div class="mb-2" :class="this.config.texto1 == '100' ? 'col-12' : 'col-9'" style="align-items: center;display: flex;justify-content: center; padding: 0px 8px 0px 8px;">
              <label class="custom-html-style" :style="this.config.brasao == '100' ? '' : '' " style="text-align: center; margin: 0px !important" v-html="ataInfo.texto_logo" ></label>
            </div>

            <label class="m-0 mb-2" style="text-align: center;">{{ escola_nome }}<br> {{segmento_nome}} - {{ serie_nome }} - {{ turma_nome }} </label>

            <div class="col-12 custom-html-style m-0" style="text-align: center; padding: 0px 8px 0px 8px;" v-html="ataInfo.titulo + ' N° ' +  ataInfo.numero "></div>

            <div class="col-12" style="text-align: center; margin-bottom: 0px; padding: 0px 8px 0px 8px;">
              <label v-html="ataInfo.texto" style="text-align: justify; margin-bottom: 0px !important; word-wrap: break-word; margin-right: 8px;"></label>
            </div>
          </div>


          <div class="col-md-12"  style="padding-left: 50px;"  v-if="modo == 1">
            <table style=" width: 100%;" class="" border=1 cellpadding=2  cellspacing=15 cellmargin="15">

              <tbody>
                <tr style="">
                  <th  class="border_info" colspan="2"><p style="margin-top: 80px;">Nº</p></th>
                  <th  class="border_info" colspan="2"><p style="margin-top: 80px;">Aluno(a)</p></th>
                  <th  class="border_info  vertical-text" colspan="2" v-for="a in alunosALL[0].notas" :key="a">
                    <p>{{a.disciplina.nome}}</p>
                  </th>
                  <th  class="border_info vertical-text" colspan="2"><p style="text-align: center;">%FREQUÊNCIA</p></th>
                  <th  class="border_info vertical-text" colspan="2"> <p>RESULTADO FINAL</p></th>
                  <th  class="border_info vertical-text" colspan="2"  v-if="ataInfo.possui_dependecia"><p>PROGRESSÃO PARCIAL</p></th>
                </tr>
              </tbody>


              <tbody>
                <tr class="" v-for="a in alunosALL"  :key="a">
                  <td class=" info-text border_info" colspan="2">
                    {{ a.numero }}
                  </td>
                  <td class=" info-text border_info"  colspan="2" style="text-align: inherit;width: 800px!important;">
                    {{ a.info.nome.toUpperCase() }} {{ a.info.sobrenome.toUpperCase() }}
                  </td>

                  <td class=" info-text border_info" colspan="2"  v-for="b in a.notas"  :key="b" >
                    {{b.notaShow}}
                  </td>

                  <td class=" info-text border_info" colspan="2">
                    <span v-if="a.selectedPresenca == '+'"> +{{ a.porcentagem_para_passar }}%</span>
                    <span v-else> -{{ a.porcentagem_para_passar }}%</span>
                  </td>

                  <td class="info-text border_info" colspan="2">

                    <span v-if="a.situacao == 'PR' && ataInfo.segmento.tipo == 0">{{nomenclaturaData.aprovadoCrecheAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'PR' && ataInfo.segmento.tipo != 0">{{nomenclaturaData.aprovadoAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'RET'">{{nomenclaturaData.reprovadoAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'TR'">{{nomenclaturaData.transferidoAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'EV'">{{nomenclaturaData.evadidoAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'FAL'">{{nomenclaturaData.falecidoAbreviacao}}</span>
                    <span v-else-if="a.situacao == 'PRC'">{{nomenclaturaData.aprovadoPeloConselhoAbreviacao}}</span>
                    <span v-else>{{nomenclaturaData.retencaoJustificadaAbreviacao}}</span>
                  </td>
                  <td  class="info-text border_info" v-if="ataInfo.possui_dependecia">
                    <span v-if="a.dependenciasDoALuno != undefined">{{a.dependenciasDoALuno.map(p => p.disciplina.sigla).join(", ")}}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div class="col-12 md:col-12 ml-0" style="font-weight: bold; background-color: #e9ecef; font-size: 12px; padding: 3px; border-radius: 4px;" v-if="ataInfo.segmento.tipo === 0"> -->
            <div class="col-12 md:col-12 ml-0" style="font-weight: bold; background-color: #e9ecef; font-size: 12px; padding: 3px; border-radius: 4px;">
              <!--<p class=" m-0">{{nomenclaturaData.aprovadoCreche}} ({{nomenclaturaData.aprovadoCrecheAbreviacao}}),{{ nomenclaturaData.transferido }} ({{nomenclaturaData.transferidoAbreviacao}}), {{ nomenclaturaData.evadido }} ({{nomenclaturaData.evadidoAbreviacao}}), {{ nomenclaturaData.falecido }} ({{nomenclaturaData.falecidoAbreviacao}}) </p>-->
              <p class=" m-0">{{nomenclaturaData.aprovado}} ({{nomenclaturaData.aprovadoAbreviacao}}), {{ nomenclaturaData.aprovadoPeloConselho }} ({{nomenclaturaData.aprovadoPeloConselhoAbreviacao}}), {{ nomenclaturaData.retencaoJustificada }} ({{nomenclaturaData.retencaoJustificadaAbreviacao}}), {{ nomenclaturaData.reprovado }} ({{nomenclaturaData.reprovadoAbreviacao}}), {{ nomenclaturaData.transferido }} ({{nomenclaturaData.transferidoAbreviacao}}), {{ nomenclaturaData.evadido }} ({{nomenclaturaData.evadidoAbreviacao}}), {{ nomenclaturaData.falecido }} ({{nomenclaturaData.falecidoAbreviacao}})</p>
            </div>
            <!-- <div class="col-12 md:col-12 ml-0" style="font-weight: bold; background-color: #e9ecef; font-size: 12px; padding: 3px; border-radius: 4px;" v-if="ataInfo.segmento.tipo === 1">
              <p class=" m-0">{{nomenclaturaData.aprovado}} ({{nomenclaturaData.aprovadoAbreviacao}}), {{ nomenclaturaData.aprovadoPeloConselho }} ({{nomenclaturaData.aprovadoPeloConselhoAbreviacao}}), {{ nomenclaturaData.reprovado }} ({{nomenclaturaData.reprovadoAbreviacao}}), {{ nomenclaturaData.transferido }} ({{nomenclaturaData.transferidoAbreviacao}}), {{ nomenclaturaData.evadido }} ({{nomenclaturaData.evadidoAbreviacao}}), {{ nomenclaturaData.falecido }} ({{nomenclaturaData.falecidoAbreviacao}})</p>
            </div> -->

            <p class=" m-0 mt-5" style="text-align: right;">Italva, _____ de ______________ de ________</p>
            <table class="col-12 borda-externa mt-5">
              <tbody>
                <tr align="center">
                  <td>___________________</td>
                  <td>___________________</td>
                  <td>___________________</td>
                </tr>
                <tr align="center">
                  <td>Professor (a)</td>
                  <td>Diretor (a)</td>
                  <td>Supervisor (a)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="row" v-if="modo == 2">
          <div class="col-12">
            <button style="float:right;border: 1px solid #b5b5b5;" @click="modo = 1" class="btn" >Voltar</button>
            <button  style="float:right;border: 1px solid #b5b5b5;margin-right:5px;" @click="mudancanCabecalhoAta()" class="btn btn-success" :disabled = "loading == true"> Confirmar Mudança</button>
          </div>

          <!--Modo edição -->
          <div :class="this.config.brasao == '100' ? 'col-12' : 'col-3'">
            <div v-if="ataDadosEdicao.logo"  style=" text-align: center;" >
              <img :src="base_url+'/storage/'+ataDadosEdicao.logo"  :style="config.brasao == '100' ? 'width: 24%;height: 179px;' : 'width:100%;' " />
            </div>
          </div>

          <div :class="this.config.texto1 == '100' ? 'col-12' : 'col-9'" style="align-items: center;display: flex;justify-content: center;">
            <pm-Editor v-model="ataDadosEdicao.texto_logo" editorStyle="height: 100px" style="width: 100%;">
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                  <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                  <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
              </template>
            </pm-Editor>
          </div>

          <div class="col-12" style="text-align: center;margin-top:30px;">
            <pm-Editor v-model="ataDadosEdicao.titulo" editorStyle="height: 80px" style="width: 100%;">
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                  <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                  <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
              </template>
            </pm-Editor>
          </div>


          <div class="col-12" style="text-align: center;margin-top:30px;">
            <pm-Editor v-model="ataDadosEdicao.texto" editorStyle="height: 150px" style="width: 100%;">
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                  <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                  <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
              </template>
            </pm-Editor>
          </div>
        </div>
      </template>
    </pm-Card>
  </pm-Dialog>

  <div>
    <pm-Dialog :header="'Alterar dados do aluno ' + infoAluno.nomeCompleto" v-model:visible="modalAlterarDadosAluno" :style="{ width: '65vw' }" :breakpoints="{ '960px': '80vw', '641px': '100vw' }">
      <div class="col-md-12">
        <h6 style="display: flex; ">
          <p style="color:#838383">ATA:</p>&nbsp; <p style="background-color: #e9ecef;border-radius: 6px;padding: 4px;font-size: 12px;color:#838383"> {{ ata_numero }} </p>
        </h6>
        <button
          class="btn btn-danger button-generate"
          @click="gerarPdfResultadoAluno()"
          title="Gerar Impressão"
          style="float: right;"
        >
          <i class="fa fa-file-pdf-o"></i> Pdf
        </button>
      </div>
      <div  id="content-print-resultado-aluno-rel" class="field col-12 md:col-12" style="padding-top: 16px">
        <div class="row">

          <div class="col-md-12" >
            <strong>RESULTADO FINAL DO ALUNO:  {{infoAluno.nomeCompleto}}</strong>

            <div class="col-md-12" style="padding-top:20px;">
              <table class="table_infoAluno" border=1 cellpadding=15  cellspacing=15 cellmargin="15" style="margin-left: 13px;">
                <thead>
                  <tr>
                    <th class="th-the" colspan="2" cellpadding=15 v-for="a in infoAluno.notas" :key="a"> <p  class=" info-text" style="margin-top: 90px;">{{a.disciplina.nome}} </p></th>
                    <th style="text-align: center;"   class="th-the" colspan="2" cellpadding=15> <p class=" info-text">%FREQUÊNCIA</p></th>
                    <th style="text-align: center;"   class="th-the" colspan="2" cellpadding=15> <p class=" info-text">RESULTADO FINAL</p></th>
                    <th style="text-align: center;"   class="th-the" colspan="2" cellpadding=15> <p class=" info-text"  v-if="ataInfo.possui_dependecia">PROGRESSÃO PARCIAL(DEPENDÊNCIA)</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td class="" colspan="2" v-for="a in infoAlunoTemp.notas"  :key="a" >
                      <input   v-if="a.passou == 1" disabled v-model="a.notaShow" class="notainput" type="number" inputmode="numeric" id="idnota" name="nota" min="0" max="100">
                      <input  v-else v-model="a.notaShow" class="notainput" type="number" inputmode="numeric" id="idnota" name="nota" min="0" max="100" style="background-color: #ffff;border-radius: 200px;">

                    </td>
                    <td class="" colspan="2">


                      <select class="select" id="passou_por_presenca" name="presenca" v-model="infoAlunoTemp.selectedPresenca"  :disabled="infoAluno.teve_porcentagem_para_passar"
                        :class="infoAluno.teve_porcentagem_para_passar ? '' : 'colorSelectAta' ">
                        <option value="+">+{{ infoAluno.porcentagem_para_passar }}%</option>
                        <option  value="-">-{{ infoAluno.porcentagem_para_passar }}%</option>
                      </select>
                    </td>
                    <td class="" colspan="2">
                      <select class="select" id="passou_por_presenca" name="presenca"  v-model="infoAlunoTemp.situacao"
                        :class="'colorSelectAta' ">
                        <option :disabled="infoAlunoTemp.situacao === 'RET' || infoAlunoTemp.situacao === 'PRC'" value="PR">{{nomenclaturaData.aprovadoAbreviacao}}</option>
                        <option :disabled="infoAlunoTemp.situacao === 'PR' || infoAlunoTemp.situacao === 'RJ'" value="PRC">{{nomenclaturaData.aprovadoPeloConselhoAbreviacao}}</option>
                        <option :disabled="infoAlunoTemp.situacao === 'PR' || infoAlunoTemp.situacao === 'RJ'" value="RET">{{nomenclaturaData.reprovadoAbreviacao}}</option>
                        <option :disabled="infoAlunoTemp.situacao === 'RET' || infoAlunoTemp.situacao === 'PRC'" value="RJ">{{nomenclaturaData.retencaoJustificadaAbreviacao}}</option>
                      </select>
                    </td>
                    <td class="" colspan="2" v-if="ataInfo.possui_dependecia">
                      <pm-MultiSelect   :disabled="!infoAluno.possiveis_dependencias.length" v-model="infoAluno.dependenciasDoALuno" :options="infoAluno.possiveis_dependencias" optionLabel="disciplina.nome"  maxSelectedLabels="2" :style="{ width: '100%', border: 'none', outline: 'none'}" />
                    </td>

                  </tr>
                </tbody>

              </table>
            </div>
            <div v-if="(ataInfo.segmento.tipo == 2 || ataInfo.segmento.tipo == 3 && ataInfo.serie.tipo == 2) && infoAluno.perdeu_em_presenca_disciplinas.length" class="col-md-12" style="padding-top:20px;">
              <p>
                <strong>Alerta:</strong>
                O aluno(a) não atingiu a porcentagem mínima de presença de {{infoAluno.porcentagem_para_passar}}% necessária para ser aprovado nas seguintes disciplinas:
              </p>
              <ul>
                <li v-for="disciplina in infoAluno.perdeu_em_presenca_disciplinas">
                  - {{ disciplina.disciplina.nome }}
                </li>
              </ul>
            </div>
            <div v-else-if="ataInfo.segmento.tipo != 2 && ataInfo.segmento.tipo != 0 && !infoAluno.teve_porcentagem_para_passar" class="col-md-12" style="padding-top:20px;">
              <p>
                <strong>Alerta:</strong>
                O aluno(a) não atingiu a porcentagem mínima de presença de {{infoAluno.porcentagem_para_passar}}% necessária para ser aprovado!
              </p>
            </div>

            <div class="row">
              <div class="col-12 md:col-12" style="margin-left: 13px;padding: 13px;">
                <span class="p-float-label" style="width: 100%;">
                  <textarea id="observacao" name="observacao" rows="4" cols="5" style="width: 100%; height: 100%;" v-model="infoAluno.observacao">
                  </textarea>
                  <label>Observação</label>
                </span>
              </div>

              <div class="col-12 md:col-10">
                <p  style="font-weight: bold;background-color: #e9ecef; font-size: 12px; margin-left: 17px; padding: 3px;" v-if="ataInfo.segmento.tipo == 0">{{nomenclaturaData.aprovadoCrecheAbreviacao}} = {{nomenclaturaData.aprovadoCreche}}, {{nomenclaturaData.transferidoAbreviacao}} = {{ nomenclaturaData.transferido }}, {{nomenclaturaData.evadidoAbreviacao}} = {{ nomenclaturaData.evadido }}, {{nomenclaturaData.falecidoAbreviacao}} = {{ nomenclaturaData.falecido }}</p>
                <p  style="font-weight: bold;background-color: #e9ecef; font-size: 12px; margin-left: 17px; padding: 3px;" v-else>{{nomenclaturaData.aprovadoAbreviacao}} = {{nomenclaturaData.aprovado}}, {{nomenclaturaData.aprovadoPeloConselhoAbreviacao}} = {{nomenclaturaData.aprovadoPeloConselho}}, {{nomenclaturaData.reprovadoAbreviacao}} = {{nomenclaturaData.reprovado}}, {{ nomenclaturaData.retencaoJustificadaAbreviacao }} = {{ nomenclaturaData.retencaoJustificada }},{{nomenclaturaData.transferidoAbreviacao}} = {{ nomenclaturaData.transferido }}, {{nomenclaturaData.evadidoAbreviacao}} = {{ nomenclaturaData.evadido }}, {{nomenclaturaData.falecidoAbreviacao}} = {{ nomenclaturaData.falecido }}</p>
                <!-- <p  style="font-weight: bold;background-color: #e9ecef; font-size: 12px; margin-left: 17px; padding: 3px;">*Alunos {{nomenclaturaData.aprovado}} não possuem opção de alteração dos dados, apenas exibição.</p> -->
                <p  style="font-weight: bold;background-color: #e9ecef; font-size: 12px; margin-left: 17px; padding: 3px;">*Os alunos da creche recebem ' {{nomenclaturaData.aprovadoAbreviacao}}' por padrão, independentemente do percentual de presença.</p>
              </div>
              <div class="col-12 md:col-2" v-if="dadosAlunoEmPdf == false">
                <pm-Button style="float: right;" type="button" :disabled="!anoSitucao" @click="salvar()" icon="pi pi-save"  label="Salvar" title="Salvar" class="p-button-success p-button-sm btn-color table_style" >
                </pm-Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pm-Dialog>
  </div>

</template>

<script>
import { defineComponent } from "vue";

import axios from 'axios'
import { Atas } from "@/pages/admin/pages/relatorios/services/ata.service";
import { nomenclaturaSituacaoAluno } from '@/utils/nomenclatura';

export default defineComponent({

  props: ['turma_id', 'ata_id', 'ata_numero', 'escola_nome', 'turma_nome', 'segmento_nome', 'serie_nome'],
  components:{

  },
  data() {
    return {
      nomenclaturaData: nomenclaturaSituacaoAluno,
      filters:'',
      showModalRelatorio:false,
      carregandoPagina:false,
      alunosALL: [],
      infoAtas: {
        ano:'2023',
        situacao:1,
        turma_id:0,
        possui_dependecia:false,
      },
      buscar:{
        ano:'2023',
        numero: '',
        turma_nome:'',
        segmento_nome:'',
        serie_nome:'',
        escola_nome:'',
      },
      relatorio:[],
      modalAlterarDadosAluno:false,
      infoAluno: {

      },
      ataInfo: {},
      modo: 1,
      config:[],
      modelo:'padrao',
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      modeloExibicao: [
        {
           id:1,
           nome: "Modelo 1"
        },
        {
          id:2,
          nome: "Modelo 2"
        }
      ],
      exibicaoCabecalho:{
           id:1,
           nome: "Modelo 1",
      },
      ataDadosEdicao:{},
      loading:false,
      dadosAlunoEmPdf: false,
      infoAlunoTemp: {},
    };
  },
  computed: {
    pages() {
      if(this.perPage && this.perPage !== "0"){
        return Math.ceil(this.listaAlunosTransferidos.total / this.perPage)
      }else{
        return this.listaAlunosTransferidos.total;
      }
    },
  },
  methods: {
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ata.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarExcel(){
      const divm = document.querySelectorAll('#content-print-rel')[ document.querySelectorAll('#content-print-rel').length - 1 ];
      const cloned = divm.cloneNode(true); // isso aqui é essencial, este clone e criação da div em tempo real para funcionar com modal
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }
      section.appendChild(cloned);

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = section.outerHTML.replace(/ /g, '%20');

      a.href = data_type + ', ' + table_html;
      a.download = 'ata.xls';
      a.click();
    },

    gerarPdfResultadoAluno() {
      this.dadosAlunoEmPdf = true;
      const divm = document.querySelectorAll('#content-print-resultado-aluno-rel')[ document.querySelectorAll('#content-print-resultado-aluno-rel').length - 1 ];
        const cloned = divm.cloneNode(true); // isso aqui é essencial, este clone e criação da div em tempo real para funcionar com modal
        let section = document.getElementById("print");

        if (!section) {
          section = document.createElement("div");
          section.id = "print";
          document.body.appendChild(section);
        }

        // Limpar o conteúdo da div "print" antes de adicionar o novo conteúdo
        section.innerHTML = "";

        section.appendChild(cloned);
        window.print();
      this.dadosAlunoEmPdf = false;
      sessionStorage.setItem('gerou_ata', 1);


    },
    gerarPdf() {
        const divm = document.querySelectorAll('#content-print-rel')[ document.querySelectorAll('#content-print-rel').length - 1 ];
        const cloned = divm.cloneNode(true); // isso aqui é essencial, este clone e criação da div em tempo real para funcionar com modal
        let section = document.getElementById("print");

        if (!section) {
          section = document.createElement("div");
          section.id = "print";
          document.body.appendChild(section);
        }

        // // Limpar o conteúdo da div "print" antes de adicionar o novo conteúdo
        section.innerHTML = "";

        section.appendChild(cloned);
        window.print();
        sessionStorage.setItem('gerou_ata', 1);
    },



    async mudancanCabecalhoAta() {
      this.loading = true;
      Atas.update(this.ataDadosEdicao).then((resp) => {
          this.ataInfo.texto = resp.data.texto;
          this.ataInfo.texto_logo = resp.data.texto_logo;
          this.ataInfo.titulo = resp.data.titulo;

          this.modo = 1;
      }).catch((err) => {
        }).finally(() => {
          this.loading = false;
      });
    },
    dadosAtas() {
      this.ataDadosEdicao = { ...this.ataInfo };
      this.modo = 2;
    },
    mudarPadrao(){
      let newl = ""
      if(this.exibicaoCabecalho.id == 1){
        newl={
          brasao: '100',
          texto1: '100',
        }
      }else{
        newl={
          brasao: '30',
          texto1: '70',
        }
      }
      this.config = newl
    },
    salvar() {
      if (this.infoAlunoTemp.situacao === this.nomenclaturaData.retencaoJustificadaAbreviacao && this.infoAluno.observacao == null) {
        this.$vaToast.init({
            message: `Observação obrigatória para os alunos com ${this.nomenclaturaData.retencaoJustificada}`,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
        return false;
      }
      this.carregandoPagina = true;
      this.infoAluno.notas = this.infoAlunoTemp.notas;
      this.infoAluno.dependenciasDoALuno = this.infoAlunoTemp.dependenciasDoALuno;
      this.infoAluno.situacao = this.infoAlunoTemp.situacao;
      this.infoAluno.selectedPresenca = this.infoAlunoTemp.selectedPresenca;
      //verificar se o conselho aprovou o aluno
      if (this.infoAluno.selectedPresenca == '+' && this.infoAluno.ata_passou_aluno_em_presenca == 0 && this.infoAluno.teve_porcentagem_para_passar == false) {
        this.infoAluno.ata_passou_aluno_em_presenca = 1;
      }//verificar se o aluno foi aprovado pelo conselho e ele nao passou de fato.
      else if (this.infoAluno.ata_passou_aluno_em_presenca == 1 && this.infoAluno.selectedPresenca == '-') {
        this.infoAluno.ata_passou_aluno_em_presenca = 0;
      }

      //verficiar se a pessoa alterou as notas.
      for (let i = 0; i < this.infoAluno.notas.length; i++) {
        if (this.infoAluno.notas[i].nota_alterada != '' && parseInt(this.infoAluno.notas[i].nota) != parseInt(this.infoAluno.notas[i].notaShow)) {
          this.infoAluno.notas[i].nota_alterada = this.infoAluno.notas[i].notaShow;
        }
        else if (parseInt(this.infoAluno.notas[i].nota) != parseInt(this.infoAluno.notas[i].notaShow)) {
          this.infoAluno.notas[i].nota_alterada = this.infoAluno.notas[i].notaShow;
        }
      }
      if (this.infoAluno.dependenciasDoALuno.length > 2) {
        this.$vaToast.init({
            message: "Por favor, escolha apenas 2 disciplinas para a dependência do aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'warning',
            fullWidth: false,
        });
        return false;
      }
      this.infoAluno.turma_id = this.turma_id;
      Atas.adicionarInfoNovaParaAluno(this.infoAluno).then((resp) => {
          this.$vaToast.init({
            message: "Alterado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.carregandoPagina = false;
          this.modalAlterarDadosAluno = false;
          this.buscarAta();
        });
    },
    alterarDadosDoAluno(dadosAluno) {
      this.infoAlunoTemp.notas = [];
      this.infoAlunoTemp.dependenciasDoALuno = [];
      this.infoAlunoTemp.situacao = dadosAluno.situacao;
      this.infoAlunoTemp.selectedPresenca = dadosAluno.selectedPresenca;

      dadosAluno.notas.forEach(element => {
        const obj = {
          nota: element.nota,
          notaShow: element.notaShow,
          nota_alterada: element.nota_alterada,
          passou: element.passou ,
          disciplina: element.disciplina,
        }
        this.infoAlunoTemp.notas.push(obj)
      });

      dadosAluno.dependenciasDoALuno.forEach(element =>{
        const obj = {
          passou: element.passou,
          nota: element.nota,
          nota_alterada: element.nota_alterada,
          disciplina: element.disciplina
        }
        this.infoAlunoTemp.dependenciasDoALuno.push(obj)
      });

      this.infoAluno = dadosAluno;
      this.modalAlterarDadosAluno = true;
    },
    normalizarOsDados() {
      for (let i = 0; i < this.alunosALL.length; i++) {
        this.alunosALL[i].numero = i + 1; //adicionar numeracao para os alunos
        //criando uma variavel que recebe o nome todo
        this.alunosALL[i].nomeCompleto =  this.alunosALL[i].info.nome.toUpperCase() + ' ' +  this.alunosALL[i].info.sobrenome.toUpperCase();


        //a variavel 'notaShow' é que é exibida para o usuario
        for (let j = 0; j < this.alunosALL[i].notas.length; j++) {
          if (this.alunosALL[i].notas[j].nota_alterada == '')
            {this.alunosALL[i].notas[j].notaShow = this.alunosALL[i].notas[j].nota;}           //Se o aluno nao teve a nota mudada eu vou exibir para o usuario a nota original do aluno.
          else
            {this.alunosALL[i].notas[j].notaShow = this.alunosALL[i].notas[j].nota_alterada;} //Se o aluno teve a nota mudada, eu vou exibir para o usuario a nota que foi alterada.

        }
        //Se o aluno ja passou em presenca ou o usuario mudou de -75 para +75 eu vou exibir 75
        if (!this.alunosALL[i].perdeu_em_presenca_disciplinas.length || this.alunosALL[i].ata_passou_aluno_em_presenca) {
          this.alunosALL[i].selectedPresenca = '+';
        }
        else { //caso contrario o aluno perdeu em presenca
          this.alunosALL[i].selectedPresenca = '-';
        }
      }
    },
    async buscarAta() {
      Atas.show(this.ata_id).then((resp) => {
          this.ataInfo = resp.data;
          const newl={
            brasao: '100',
            texto1: '100',
          }
          this.config = newl;
      }).catch((err) => {
        }).finally(() => {
      });
    },
    async buscarAlunos() {
      const info = {
        turma_id: this.turma_id,
        ata_id: this.ata_id
      }
      this.carregandoPagina = true;
      Atas.buscarAlunosParaAta(info).then((resp) => {
          this.alunosALL = resp.data;
          this.normalizarOsDados();
      }).catch((err) => {
          const errorMessage = err.response.data.erro;
          this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
          });
      }).finally(() => {
          this.carregandoPagina = false;
      });
    },
  },
  async beforeMount() {
    document.getElementById('printme').innerHTML = '';
    this.anoSitucao = parseInt(sessionStorage.getItem("anoSitucao"));
    if (this.ata_id == null) {
      this.$router.go(-1);
    }
    await this.buscarAta();
    await this.buscarAlunos();

  },
});
</script>

<style lang="scss" scoped>

.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}

.position {
  // writing-mode: vertical-rl;
  // text-orientation: mixed;
  // float: left;
}

.legenda{
  font-weight: bold;
  background-color: #e9ecef;
  font-size: 12px;
  padding: 3px;
  border-radius: 4px;
}
</style>

<style>
    .border_info{
      text-align: center;
      border: 1px solid black;
    }

  .notainput {
      border: none;
      outline: none;
      text-align: center; /* Center the text horizontally */
      display: block; /* Set the input to be a block element (span the full width) */
      margin: 0 auto; /* Center the input horizontally within its container (parent) */
      background-color: transparent;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]{
      -moz-appearance: textfield;
    }

    .select {
      text-align: center; /* Center the text horizontally */
      border: none;
      outline: none;
      width: 100%;
      box-sizing: border-box; /* Optional: Include padding and border within the width */
      background-color: #f2f2f2;
    }

    .table_infoAluno {
      background-color: #f2f2f2;
      border: 1px solid #B5B5B5;


    }
    .table_relatorio_alunos {
      width: 100%;
      background-color: transparent !important;
      border: 1px solid #0f0e0e;
    }

    .vertical-text {
      text-align: center;
      writing-mode: vertical-rl;
      white-space: pre-wrap;
      /* word-wrap: break-word; */
      transform: rotate(180deg);
      font-size: 12px;
      padding: 3px;
      /* padding-right: 20px; */
    }

    .info-text {
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      overflow-wrap: break-word;
      white-space: nowrap;
      padding: 4px 8px 4px 8px;
      font-weight: 500;
    }


  .custom-html-style {
    margin-bottom: 0;
  }
  .lbList{
    background-color: #21ae28;
    color: #fff;
    padding: 5px;
    width: 100px;
    text-align: center;
    margin-bottom: 0px!important;
  }

  .lbList{
    background-color: #21ae28;
    color: #fff;
    padding: 5px;
    width: 100px;
    text-align: center;
    margin-bottom: 0px!important;
  }


</style>


<style>
p{
  margin-bottom: 0px;
}
@media screen {
  #print {
    display: none;
  }
}

@media print {

  @page {
  size: A4 portrait !important;
  margin-top: 5px !important;

  /* margin-right: 5px; */
}
body * {
  visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    top:0;
  }
  .ajuste_table {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 25px;
  }

  #table_relatorio_alunos {
    background-color: red;
  }
}
.colorSelectAta{
  background-color: #fff !important;
}

.custom-html-style {
    margin-bottom: 0;
}

/* .custom-html-style {
    margin-bottom: 0 !important;/
} */
</style>
