<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
  
    <div class="col-md-12" style="margin-top:-15px;padding: 14px;" >
      <atasAlunos 
        :turma_id="turma_id"
        :ata_id="ata_id"
        :ata_numero="ata_numero"
        :escola_nome="escola_nome"
        :turma_nome="turma_nome"
        :segmento_nome="segmento_nome"
        :serie_nome="serie_nome"

      />

    </div>
  </section>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import axios from 'axios';

  import atasAlunos from "./components/atasAlunos.vue"

  
  export default defineComponent ({
    props: ['turma_id', 'ata_id', 'ata_numero', 'escola_nome', 'turma_nome', 'segmento_nome', 'serie_nome'],
    components:{
      atasAlunos
    },
    data(){
      return{
        base_url: axios.defaults.baseURL.replace('/api/v1',''),
        home: 
        {icon: 'pi pi-home', to: '/relatorios/dashboard'},
        items_bread: [
          {label: 'Atas'},
          {label: 'Atas Alunos'},
        ],
  
        visivel:false,
        filtro:[
          {
            nome:"Nome",
            check:true,
            _id:0,
          },
          {
            nome:"Filiação",
            check:true,
            _id:1,
          },
        ],
        dados:[]
      }
    },
    async beforeMount() {

    },
    methods: {
  
    }
  });
  </script>
  
  <style>
  
  </style>
  